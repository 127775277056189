import React, { useState, useEffect } from 'react';
import { apiFetchGoals, apiSaveGoals } from '../api';

function MyGoals({ email }) {
  const [goals, setGoals] = useState({
    calories: '',
    proteins: '',
    carbohydrates: '',
    fats: ''
  });

  useEffect(() => {
    const getGoals = async () => {
      const goals = await apiFetchGoals(email);
      if (goals) {
        setGoals(goals);
      }
    };

    getGoals();
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newGoals = {
      ...goals,
      [name]: value,
    };

    if (name === 'proteins' || name === 'carbohydrates' || name === 'fats') {
      const proteins = parseFloat(newGoals.proteins) || 0;
      const carbohydrates = parseFloat(newGoals.carbohydrates) || 0;
      const fats = parseFloat(newGoals.fats) || 0;
      newGoals.calories = (4 * proteins + 4 * carbohydrates + 9 * fats).toFixed(2);
    }

    setGoals(newGoals);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await apiSaveGoals(email, goals);
    alert('Goals saved successfully');
  };

  return (
    <div className="container mt-5">
      <h2>My Goals</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="calories" className="form-label">Calories</label>
          <input
            type="number"
            className="form-control"
            id="calories"
            name="calories"
            value={goals.calories}
            onChange={handleChange}
            disabled
          />
        </div>
        <div className="mb-3">
          <label htmlFor="proteins" className="form-label">Proteins</label>
          <input
            type="number"
            className="form-control"
            id="proteins"
            name="proteins"
            value={goals.proteins}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="carbohydrates" className="form-label">Carbohydrates</label>
          <input
            type="number"
            className="form-control"
            id="carbohydrates"
            name="carbohydrates"
            value={goals.carbohydrates}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="fats" className="form-label">Fats</label>
          <input
            type="number"
            className="form-control"
            id="fats"
            name="fats"
            value={goals.fats}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Save Goals</button>
      </form>
    </div>
  );
}

export default MyGoals;
