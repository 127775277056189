import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiFetchKnownFoods } from '../api';

const EditFoodInfoModal = ({ show, handleClose, food, onSave }) => {

    const [selectedFood, setSelectedFood] = useState({
        proteins_100g: food?.matchingFood?.proteins_100g || 0,
        carbohydrates_100g: food?.matchingFood?.carbohydrates_100g || 0,
        fats_100g: food?.matchingFood?.fats_100g || 0
      });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedFood(prev => ({
          ...prev,
          [name]: parseFloat(value) || 0
        }));
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(selectedFood, food.matchingFood.id);
      };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar macros de {food?.food}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Proteins per 100g</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.1"
                            name="proteins_100g"
                            value={selectedFood.proteins_100g}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Carbohydrates per 100g</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.1"
                            name="carbohydrates_100g"
                            value={selectedFood.carbohydrates_100g}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Fats per 100g</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.1"
                            name="fats_100g"
                            value={selectedFood.fats_100g}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditFoodInfoModal;