// If REACT_APP_API_URL is not set, use an empty string to use relative paths
// (this is the case in production)
const API_URL = process.env.REACT_APP_API_URL || '';

export const getAuthHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return {
    'Content-Type': 'application/json',
    'Authorization': user && user.token ? `Bearer ${user.token}` : '',
  };
};

// Función auxiliar para manejar las respuestas de fetch de manera consistente
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.message || 'Network response was not ok');
  }
  return await response.json();
};

export const apiLogFood = async (message, sender_id, date) => {
  const response = await fetch(`${API_URL}/log_food`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      message, 
      sender_id, 
      date 
    }),
  });
  return handleResponse(response);
};

export const apiLogFoodInMeal = async (meal_type, message, sender_id, date) => {
  const response = await fetch(`${API_URL}/log_food_in_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      sender_id, 
      meal_type, 
      message, 
      date 
    }),
  });
  return handleResponse(response);
};

{/* Unused function */}
export const apiGetFoodLogEntry = async (email, date, mealType, entryId) => {
  const response = await fetch(`${API_URL}/get_food_log_entry`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      entry_id: entryId 
    }),
  });
  return handleResponse(response);
};

export const apiClearMeal = async (email, meal_type, date) => {
  const response = await fetch(`${API_URL}/clear_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      sender_id: email, 
      meal_type, 
      date }),
  });
  return handleResponse(response);
};

export const apiGetOrCreateDayLog = async (email, date) => {
  const response = await fetch(`${API_URL}/get_or_create_day_log`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date 
    }),
  });
  return handleResponse(response);
};


export const apiSaveGoals = async (email, goals) => {
  const response = await fetch(`${API_URL}/save_goals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      goals }),
  });
  return handleResponse(response);
};

export const apiFetchGoals = async (email) => {
  const response = await fetch(`${API_URL}/get_goals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

export const apiFetchKnownFoods = async (email) => {
  const response = await fetch(`${API_URL}/get_known_foods`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateKnownFood = async (email, id, updatedData) => {
  const response = await fetch(`${API_URL}/update_known_food`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      id, 
      updatedData 
    }),
  });
  return handleResponse(response);
};


export const apiUpdateFoodLogEntry = async (email, date, mealType, entryId, foodId, newQuantity, units) => {
  const response = await fetch(`${API_URL}/update_food_log_entry`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      entry_id: entryId,
      food_id: foodId, 
      new_quantity: newQuantity,
      units: units
    }),
  });
  return handleResponse(response);
};

export const apiChangeFoodName = async (email, date, foodLogEntryId, newName) => {
  const response = await fetch(`${API_URL}/change_food_name`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date,
      food_log_entry_id: foodLogEntryId, 
      new_name: newName 
    }),
  });
  return handleResponse(response);
};


export const apiUpdateFavoriteMealEntry = async (email, mealId, entryId, newQuantity) => {
  const response = await fetch(`${API_URL}/update_favorite_meal_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId,
      entry_id: entryId,
      new_quantity: newQuantity
    }),
  });
  return handleResponse(response);  
};


export const apiDeleteKnownFood = async (email, id, force) => {
  const response = await fetch(`${API_URL}/delete_known_food`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      id,
      force
    }),
  });
  return handleResponse(response);
};

export const apiDeleteFoodLogEntry = async (email, date, mealType, entryId) => {
  const response = await fetch(`${API_URL}/delete_food_log_entry`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      entry_id: entryId 
    }),
  });
  return handleResponse(response);
};

export const apiLogout = async () => {
  const response = await fetch(`${API_URL}/logout`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  localStorage.removeItem('user');
  return handleResponse(response);
};

export const apiAddFavoriteMeal = async (email, alias, date, mealType) => {
  const response = await fetch(`${API_URL}/add_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      alias, 
      date, 
      meal_type: mealType 
    }),
  });
  return handleResponse(response);
};

export const apiGetFavoriteMeals = async (email) => {
  const response = await fetch(`${API_URL}/get_favorite_meals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

export const apiDeleteFavoriteMeal = async (email, mealId) => {
  const response = await fetch(`${API_URL}/delete_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId 
    }),
  });
  return handleResponse(response);
};

export const apiLogFavoriteMeal = async (email, mealId, date, mealType) => {
  const response = await fetch(`${API_URL}/log_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({
      email, 
      meal_id: mealId, 
      date, 
      meal_type: mealType 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateFavoriteMealAlias = async (email, mealId, newAlias) => {
  const response = await fetch(`${API_URL}/update_favorite_meal_alias`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId,
      new_alias: newAlias
    }),
  });
  return handleResponse(response);
};

export const apiAddGenericMacros = async (email, date, mealType, macros, custom_name) => {
  const response = await fetch(`${API_URL}/add_generic_macros`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date,
      meal_type: mealType,
      macros,
      custom_name: custom_name
    }),
  });
  return handleResponse(response);
};

export const apiAddManualEntry = async (email, date, mealType, name, macros, quantity, units) => {
  const response = await fetch(`${API_URL}/add_manual_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      name, 
      macros, 
      quantity, 
      units 
    }),
  });
  return handleResponse(response);
};

export const apiFetchMealNames = async (email) => {
  const response = await fetch(`${API_URL}/get_meal_names`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ email }),
  });
  return handleResponse(response);
};

export const apiUpdateMealNames = async (email, mealNames) => {
  const response = await fetch(`${API_URL}/update_meal_names`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_names: mealNames 
    }),
  });
  console.log('Response (api.js):', response);
  return handleResponse(response);
};

export const apiGetUserEntries = async (email) => {
  const response = await fetch(`${API_URL}/get_user_entries`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({
      email
    })
  });
  return handleResponse(response);
};

export const apiSignUp = async (formData) => {  
  const response = await fetch(`${API_URL}/signup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  return await response;
};

export const apiLogin = async (loginData) => {
  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginData),
  });
  return await response;
};
