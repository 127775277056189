import React from 'react';

function MealInput({ mealType, value, suggestion, onInputChange, onKeyDown }) {
  // Dividimos el valor actual por comas para manejar múltiples entradas
  const entries = value.split(',');
  const currentInput = entries[entries.length - 1].trim();

  // Obtenemos el texto antes de la entrada actual
  const valueBeforeCurrentInput = value.substring(0, value.lastIndexOf(currentInput));

  return (
      
        <div>
          <input
            type="text"
            placeholder={`Describe tu ${mealType}`}
            value={value}
            onChange={(e) => onInputChange(mealType, e)}
            onKeyDown={(e) => onKeyDown(mealType, e)}
            className="form-control"
          />
          {/* Mostrar la sugerencia en línea si existe */}
          {suggestion && (
            <div className="inline-suggestion">
              {valueBeforeCurrentInput}
              {currentInput}
              <span className="suggested-text">
                {suggestion}
              </span>
            </div>
          )}
        </div>

  );
}

export default MealInput;