import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../misterfitai-logo.png';

const NavBar = ({ isLandingPage, isLoggedIn, onLogout, isAuthPage }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white w-100">
      <div className="container-fluid">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src={logo} alt="Mister Fit Logo" className="logo-img me-2" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {isLandingPage ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Sign In</Link>
                </li>
              </>
            ) : isAuthPage ? (
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
            ) : isLoggedIn ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/">My Log</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/goals">My Goals</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/myfood">My Food</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/my-favorite-meals">My Favs</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/settings">Settings</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={onLogout}>Log out</Link>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <Link className="nav-link" to="/login">Log in</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;