import React, { useState, useEffect, useCallback } from 'react';
import {Link, useLocation} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarCustomInput from './CalendarCustomInput';
import MacroProgressBars from './MacroProgressBars';
import MealInput from './MealInput';
import GenericMacrosModal from './GenericMacrosModal';
import ManualEntryModal from './ManualEntryModal';
import EditFoodInfoModal from './EditFoodInfoModal';
import logo from '../misterfitai-logo.png';
import {
  apiLogFood, 
  apiLogFoodInMeal,
  apiDeleteFoodLogEntry,
  apiUpdateFoodLogEntry,
  apiClearMeal,
  apiAddGenericMacros,
  apiGetOrCreateDayLog,
  apiPrepareMyLogs,
  apiGetUserEntries,
  apiAddFavoriteMeal, 
  apiGetFavoriteMeals, 
  apiLogFavoriteMeal,
  apiAddManualEntry,
  apiChangeFoodName,
  apiUpdateKnownFood,
  apiFetchKnownFoods
} from '../api';

function Dashboard({
    navigate,
    userEmail,
    isLoggedIn,
    setIsLoggedIn,
    loginData,
    setLoginData,
    handleLogout,
    logUpdateCounter,
}) {
    const [input, setInput] = useState('');
    const [processing, setProcessing] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMealType, setSelectedMealType] = useState(null);
    const [favoriteMeals, setFavoriteMeals] = useState([]);
    const [showGenericMacrosModal, setShowGenericMacrosModal] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);
    const [showManualEntryModal, setShowManualEntryModal] = useState(false);
    const [showEditFoodInfoModal, setShowEditFoodInfoModal] = useState(false);
    const [selectedMealTypeForGeneric, setSelectedMealTypeForGeneric] = useState(null);
    const [selectedMealTypeForManual, setSelectedMealTypeForManual] = useState(null);
    const [userEntries, setUserEntries] = useState({});
    const [mealTypes, setMealTypes] = useState([]);
    const [suggestions, setSuggestions] = useState({});
    const [mealInputs, setMealInputs] = useState(() => {
    const initialInputs = {};
      mealTypes.forEach((mealType) => {
        initialInputs[mealType] = '';
      });
      return initialInputs;
    });
  
    const [dayLog, setDayLog] = useState({
      breakfast: [],
      morning_extra: [],
      lunch: [],
      afternoon_extra: [],
      dinner: [],
      other: [],
    });
  
    const [totalMacros, setTotalMacros] = useState({
      calories: 0,
      proteins: 0,
      carbohydrates: 0,
      fats: 0,
    });
  
    const [macrosGoals, setMacrosGoals] = useState({
      calories: 0,
      proteins: 0,
      carbohydrates: 0,
      fats: 0,
    });
  
    const updateDailyInfos = (data) => {
        setDayLog(data.day_log);
        setTotalMacros(data.day_macros);
        setMacrosGoals(data.day_goals);
        setMealTypes(data.day_meal_names);
      };
  
    const handleMealInputChange = (mealType, e) => {
      const value = e.target.value;
    
      setMealInputs((prevInputs) => ({
        ...prevInputs,
        [mealType]: value,
      }));
    
      const entries = value.split(',');
      const lastEntry = entries[entries.length - 1];
      const currentInput = value.endsWith(',') ? '' : lastEntry.trim().toLowerCase();
    
      let suggestion = '';
    
      if (currentInput.length > 0) {
        // Buscar primero en el mismo meal_type
        const sameMealTypeEntries = Object.values(userEntries).filter(
          entry => entry.meal_type === mealType && entry.food_name.toLowerCase().startsWith(currentInput)
        );
        
        if (sameMealTypeEntries.length > 0) {
          const mostFrequent = sameMealTypeEntries.reduce((prev, current) => 
            (prev.frequency > current.frequency) ? prev : current
          );
          suggestion = mostFrequent.food_name.slice(currentInput.length);
        } else {
          // Si no hay coincidencias, buscar en otros meal_types
          const otherMealTypeEntries = Object.values(userEntries).filter(
            entry => entry.food_name.toLowerCase().startsWith(currentInput)
          );
          
          if (otherMealTypeEntries.length > 0) {
            const mostFrequent = otherMealTypeEntries.reduce((prev, current) => 
              (prev.frequency > current.frequency) ? prev : current
            );
            suggestion = mostFrequent.food_name.slice(currentInput.length);
          }
        }
      }
    
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [mealType]: suggestion,
      }));
    };
  
    const handleKeyDown = (mealType, e) => {
      if (suggestions[mealType]) {
        if (e.key === 'Tab' || e.key === 'ArrowRight') {
          e.preventDefault();
          const value = mealInputs[mealType] || '';
          const entries = value.split(',');
          const lastEntry = entries[entries.length - 1];
          const currentInput = value.endsWith(',') ? '' : lastEntry.trim();
  
          // Aceptar la sugerencia
          entries[entries.length - 1] = currentInput + suggestions[mealType];
          const newValue = entries.join(', ');
  
          setMealInputs((prevInputs) => ({
            ...prevInputs,
            [mealType]: newValue,
          }));
  
          // Limpiar la sugerencia
          setSuggestions((prevSuggestions) => ({
            ...prevSuggestions,
            [mealType]: '',
          }));
        }
      }
    };
  
    const handleInputChange = (event) => {
      setInput(event.target.value);
    };
  
    const handleLogFood = async (event) => {
      event.preventDefault();
      const data = await apiLogFood(
        input, 
        userEmail, 
        selectedDate.toISOString().split('T')[0]
      );
      updateDailyInfos(data);
      setInput('');
    };
  
    const handleLogFoodInMeal = async (mealType, message) => {
      if (!message.trim()) {
        return;
      }
      if (!mealTypes.includes(mealType)) {
        console.error(`Invalid meal type: ${mealType}. Valid types are: ${mealTypes.join(', ')}`);
        return;
      }
      setProcessing((prevState) => ({
        ...prevState,
        [mealType]: `Processing input for ${mealType}`,
      }));
      try {
        const data = await apiLogFoodInMeal(
          mealType, 
          message, 
          userEmail, 
          selectedDate.toISOString().split('T')[0]
        );
        updateDailyInfos(data);
        setMealInputs((prevState) => ({
          ...prevState,
          [mealType]: '',
        }));
      } catch (error) {
        console.error('Error logging food:', error);
        alert(`Failed to log food: ${error.message}`);
      } finally {
        setProcessing((prevState) => ({
          ...prevState,
          [mealType]: '',
        }));
      }
    };
  
    const handleClearMealClick = async (mealType) => {
      const data = await apiClearMeal(
        userEmail, 
        mealType, 
        selectedDate.toISOString().split('T')[0]
      );
      updateDailyInfos(data);
    };
  
    const handleAddFavoriteMeal = async (mealType) => {
      const alias = prompt("Enter a name for this favorite meal:");
      if (alias) {
        try {
          await apiAddFavoriteMeal(
            userEmail, 
            alias, 
            selectedDate.toISOString().split('T')[0], 
            mealType
          );
          fetchFavoriteMeals();
          alert("Meal added to favorites successfully!");
        } catch (error) {
          console.error('Error adding favorite meal:', error);
          alert('Failed to add favorite meal. Please try again.');
        }
      }
    };
  
    const handleOpenGenericMacrosModal = (mealType) => {
      setSelectedMealTypeForGeneric(mealType);
      setShowGenericMacrosModal(true);
    };

  
    const handleOpenManualEntryModal = (mealType) => {
      setSelectedMealTypeForManual(mealType);
      setShowManualEntryModal(true);
    };

    
    const handleOpenEditFoodInfoModal = async (mealType, item) => {
      const knownFoods = await apiFetchKnownFoods(userEmail);
      const matchingFood = knownFoods.find(food => food.id === item.food_id);
      setSelectedFood({ ...item, mealType, matchingFood });
      setShowEditFoodInfoModal(true);
  };
  
    const handleAddGenericMacros = async (mealType, macros, custom_name) => {
      try {
        const data = await apiAddGenericMacros(
          userEmail,
          selectedDate.toISOString().split('T')[0],
          mealType,
          macros,
          custom_name
        );
        updateDailyInfos(data);
      } catch (error) {
        console.error('Error adding generic macros:', error);
        alert('Failed to add generic macros. Please try again.');
      }
    };


    const handleAddManualEntry = async (mealType, entryData) => {
      try {
        setProcessing(prevState => ({ ...prevState, [mealType]: 'Añadiendo entrada manual...' }));
        const data = await apiAddManualEntry(
          userEmail,
          selectedDate.toISOString().split('T')[0],
          mealType,
          entryData.name,
          entryData.macros,
          entryData.quantity,
          entryData.units
        );
        updateDailyInfos(data);
        setProcessing(prevState => ({ ...prevState, [mealType]: '' }));
      } catch (error) {
        console.error('Error:', error);
        setProcessing(prevState => ({ ...prevState, [mealType]: 'Error al añadir entrada manual' }));
      }
    };


    const handleSaveFoodInfo = async (newMacros, foodId) => {
      try {
        const validUpdatedData = {
          proteins_100g: newMacros.proteins_100g,
          carbohydrates_100g: newMacros.carbohydrates_100g,
          fats_100g: newMacros.fats_100g,
          source: 'user'
        };
 
        await apiUpdateKnownFood(userEmail, foodId, validUpdatedData);
 
        const data = await apiGetOrCreateDayLog(userEmail, selectedDate.toISOString().split('T')[0]);
        updateDailyInfos(data);
        setShowEditFoodInfoModal(false);
        setSelectedFood(null);
      } catch (error) {
        console.error('Error updating food:', error);
        alert('Error al actualizar los macros. Por favor, inténtelo de nuevo.');
      }
    };

    const handleSelectFavoriteForLog = (mealType) => {
      setSelectedMealType(mealType);
      navigate(`/select-favorite-meal/${selectedDate.toISOString().split('T')[0]}/${mealType}`);
    };

  
    const handleAddFavoriteToLog = async (mealId, mealType, date) => {
      console.log("Adding favorite meal to log:", mealId, mealType, date);
      try {
        const data = await apiLogFavoriteMeal(
          userEmail, 
          mealId, 
          date, 
          mealType
        );
        updateDailyInfos(data);
        navigate('/');
      } catch (error) {
        console.error('Error logging favorite meal:', error);
        alert('Failed to log favorite meal. Please try again.');
      }
    };
  
    const fetchFavoriteMeals = useCallback(async () => {
      if (isLoggedIn) {
        const meals = await apiGetFavoriteMeals(userEmail);
        setFavoriteMeals(meals);
      }
    }, [isLoggedIn, userEmail]);
  
    const fetchUserEntries = async () => {
      try {
        const entries = await apiGetUserEntries(userEmail);
        setUserEntries(entries);
      } catch (error) {
        console.error('Error fetching user entries:', error);
      }
    };
  
    const getTotalMacrosForMeal = (mealItems) => {
      const totals = mealItems.reduce((totals, item) => {
        totals.calories += item.macros.calories;
        totals.proteins += item.macros.proteins;
        totals.carbohydrates += item.macros.carbohydrates;
        totals.fats += item.macros.fats;
        return totals;
      }, { calories: 0, proteins: 0, carbohydrates: 0, fats: 0 });
    
      return {
        calories: Math.round(totals.calories),
        proteins: Math.round(totals.proteins),
        carbohydrates: Math.round(totals.carbohydrates),
        fats: Math.round(totals.fats),
      };
    };
    
    const handleLogoutClick = () => {
      localStorage.removeItem('user');
      setIsLoggedIn(false);
      setLoginData({ email: '', password: '' });
      navigate('/'); // Esto nos llevará de vuelta a la página de inicio
    };
  
    const handlePreviousDayClick = () => {
      const previousDay = new Date(selectedDate);
      previousDay.setDate(selectedDate.getDate() - 1);
      setSelectedDate(previousDay);
    };
  
    const handleNextDayClick = () => {
      const nextDay = new Date(selectedDate);
      nextDay.setDate(selectedDate.getDate() + 1);
      setSelectedDate(nextDay);
    };
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
    
  
    const handleEditEntryClick = async (mealType, item) => {
      const newQuantity = prompt(`Enter new quantity for ${item.food}:`, item.quantity);
      if (newQuantity !== null && !isNaN(newQuantity)) {
        try {
          const updatedQuantity = parseFloat(newQuantity);
          const data = await apiUpdateFoodLogEntry(
            userEmail,
            selectedDate.toISOString().split('T')[0],
            mealType,
            item.id,
            item.food_id,
            updatedQuantity,
            item.units
          );
          updateDailyInfos(data);
        } catch (error) {
          console.error('Error updating food entry:', error);
          alert('Failed to update food entry. Please try again.\n' + error);
        }
      }
    };

    const handleEditFoodName = async (mealType, item) => {
      const newName = prompt(`Enter new name for ${item.food}:`, item.food);
      if (newName !== null && newName.trim() !== '') {
        try {
          const data = await apiChangeFoodName(
            userEmail,
            selectedDate.toISOString().split('T')[0],
            item.id, // food_log_entry_id
            newName
          );
          updateDailyInfos(data);
        } catch (error) {
          console.error('Error updating food entry:', error);
          alert('Failed to update food entry. Please try again.\n' + error);
        }
      }
    };

  
    const handleDeleteLogEntryClick = async (mealType, entryId) => {
      try {
        const data = await apiDeleteFoodLogEntry(
          userEmail,
          selectedDate.toISOString().split('T')[0],
          mealType,
          entryId
        );
        updateDailyInfos(data);
      } catch (error) {
        console.error('Error deleting food entry:', error);
        alert('Failed to delete food entry. Please try again.');
      }
    };
  
    const calculateNutrientCalories = (macros) => {
      if (!macros || typeof macros !== 'object') {
        return {
          proteinCalories: 0,
          carbCalories: 0,
          fatCalories: 0,
          totalCalories: 0
        };
      }
  
      const proteinCalories = (macros.proteins || 0) * 4;
      const carbCalories = (macros.carbohydrates || 0) * 4;
      const fatCalories = (macros.fats || 0) * 9;
      const totalCalories = proteinCalories + carbCalories + fatCalories;
    
      return {
        proteinCalories,
        carbCalories,
        fatCalories,
        totalCalories
      };
    };
  
    const calculatePercentages = (nutrientCalories, totalCalories) => {
      if (totalCalories === 0) {
        return {
          proteinPercentage: 0,
          carbPercentage: 0,
          fatPercentage: 0
        };
      }
    
      return {
        proteinPercentage: (nutrientCalories.proteinCalories / totalCalories) * 100,
        carbPercentage: (nutrientCalories.carbCalories / totalCalories) * 100,
        fatPercentage: (nutrientCalories.fatCalories / totalCalories) * 100
      };
    };
  
    const nutrientCalories = calculateNutrientCalories(totalMacros);
    const goalNutrientCalories = calculateNutrientCalories(macrosGoals);
    
    // const goalPercentages = calculatePercentages(goalNutrientCalories, goalNutrientCalories.totalCalories);
    // const percentages = calculatePercentages(nutrientCalories, nutrientCalories.totalCalories);
  
    //////////////////////////////////////////////////////////////////////////////
    // EFFECTS
    //////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
      console.log("call openDayLog");
      const openDayLog = async () => {
        try {
          const formattedDate = selectedDate.toISOString().split('T')[0];
          const data = await apiGetOrCreateDayLog(userEmail, formattedDate);
          updateDailyInfos(data);
        } catch (error) {
          console.error('Error fetching day info:', error);
        }
      };
      openDayLog();
    }, [logUpdateCounter, selectedDate, userEmail]);
  
    useEffect(() => {
      console.log("call fetchUserEntries");
      fetchUserEntries();
    }, [userEmail]);
  
    useEffect(() => {
      fetchFavoriteMeals();
    }, [fetchFavoriteMeals]);
    
    useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setIsLoggedIn(true);
        setLoginData({ email: user.email, password: '' });
      }
    }, []);
  
    return (


            <div className="dashboard-container">
            <div className="date-navigation mb-3 d-flex justify-content-center align-items-center">
                <button className="btn btn-outline-secondary" onClick={handlePreviousDayClick}>
                    <i className="fa fa-chevron-left"></i>
                </button>
                <div className="mx-3">
                    {selectedDate.toDateString()}
                </div>
                <button className="btn btn-outline-secondary" onClick={handleNextDayClick}>
                    <i className="fa fa-chevron-right"></i>
                </button>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    customInput={<CalendarCustomInput />}
                    dateFormat="yyyy-MM-dd"
                    todayButton="Today"
                />
                </div>
                
                <div className="my-day-log-container">
                <div className="row header-row d-none d-xl-flex">
                    <div className="col-6"></div>
                    <div className="col-1">calories</div>
                    <div className="col-1">proteins</div>
                    <div className="col-1">carbs</div>
                    <div className="col-1">fats</div>
                    <div className="col-1"></div>
                </div>
                <div className="row header-row">
                    <div className="col-6"></div>
                    <div className="col-1"><i className="fas fa-fire"></i></div>
                    <div className="col-1"><i className="fas fa-egg"></i></div>
                    <div className="col-1"><i className="fas fa-bread-slice"></i></div>
                    <div className="col-1"><i className="fas fa-cheese"></i></div>
                    <div className="col-1"><i className="fas fa-trash"></i></div>
                </div>
                <div className="row header-row-total-macros">
                    <div className="col-2 offset-4 total-macros-row-title"></div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.calories)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.proteins)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.carbohydrates)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.fats)}</div>
                </div>

                {mealTypes.map((mealType) => (
                    
                    <div key={mealType} className="meal-section mb-4">
                    <div className="row meal-header">
                        <div className="col-4"><h3>{mealType}</h3></div>
                        <div className="col-2 justify-content-end d-flex">
                          <button 
                              onClick={() => handleAddFavoriteMeal(mealType)}
                              className="btn btn-outline-secondary btn-sm btn-icon"
                              title="Add to favorites"
                          >
                              <i className="fas fa-star"></i>
                          </button>
                          <button 
                            onClick={() => handleSelectFavoriteForLog(mealType)}
                            className="btn btn-outline-secondary btn-sm btn-icon"
                            title="Add favorite to log"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                          <button
                            onClick={() => handleOpenManualEntryModal(mealType)}
                            className="btn btn-outline-secondary btn-sm btn-icon"
                            title="Add manual entry"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                          <button 
                            onClick={() => handleOpenGenericMacrosModal(mealType)}
                            className="btn btn-outline-secondary btn-sm btn-icon"
                            title="Add generic macros"
                          >
                            <i className="fas fa-calculator"></i>
                          </button>
                        </div>

                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).calories}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).proteins}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).carbohydrates}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).fats}</div>
                        <div className="col-1 action-column">
                        <div className="action-button-container">
                            <button 
                            onClick={() => handleClearMealClick(mealType)} 
                            className="btn action-button-header btn-trash-meal"
                            >
                            <i className="fa fa-trash action-icon"></i>
                            </button>
                        </div>
                        </div>
                    </div>
                    {Array.isArray(dayLog[mealType]) && dayLog[mealType].map((item, index) => (

                        <div key={index} className="row meal-row align-items-center">
                        <div className="col-6">
                            <span 
                                onClick={() => handleEditFoodName(mealType, item)}
                                style={{cursor: 'pointer'}}
                            >
                                {item.food}
                            </span>
                            <span 
                                onClick={() => handleEditEntryClick(mealType, item)} 
                                style={{cursor: 'pointer'}}
                            >
                                ({item.quantity} {item.units})
                            </span>
                        </div>
                        <div className="col-1 macro-cell" 
                            onClick={() => handleOpenEditFoodInfoModal(mealType, item)} 
                            style={{cursor: 'pointer'}}
                            >
                                {Math.round(item.macros.calories)}  
                        </div>
                        <div className="col-1 macro-cell">{Math.round(item.macros.proteins)}</div>
                        <div className="col-1 macro-cell">{Math.round(item.macros.carbohydrates)}</div>
                        <div className="col-1 macro-cell">{Math.round(item.macros.fats)}</div>
                        <div className="col-1 action-column">
                            <div className="action-button-container">
                            <button 
                                onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteLogEntryClick(mealType, item.id);
                                }} 
                                className="btn action-button btn-trash-food"
                                >
                                <i className="fas fa-trash action-icon"></i>
                            </button>
                            </div> {/* action-button-container */}
                        </div> {/* action-column */}
                        </div> // #TODO-LLM: por qué no acepta comentarios JSX ese div?
                    ))}
                    <div className="row add-food-row align-items-center">
                    <div className="col-10 position-relative input-container">
                        <MealInput
                        key={mealType}
                        mealType={mealType}
                        value={mealInputs[mealType] || ''}
                        suggestion={suggestions[mealType]}
                        onInputChange={handleMealInputChange}
                        onKeyDown={handleKeyDown}
                        />  
                    </div>
                      <div className="col-1">
                        <button 
                            onClick={() => handleLogFoodInMeal(mealType, mealInputs[mealType])} 
                            className="btn btn-secondary"
                            title="Add food"
                        >
                            <i className="fas fa-robot"></i>
                        </button>
                      </div>
                      {processing[mealType] && <div className="col-12 text-center mt-2">{processing[mealType]}</div>}
                    </div>
                    </div> 
                ))}

                <div className="summary-container">
                    <MacroProgressBars 
                    totalMacros={totalMacros} 
                    macrosGoals={macrosGoals}
                    />
                </div> {/* summary-container */}
                </div> {/* my-day-log-container */}

                <div className='row'>
                <div className='col-10'>
                    <form onSubmit={handleLogFood} className="mt-5 mb-3">
                    <textarea
                        value={input}
                        onChange={handleInputChange}
                        placeholder="Enter your food log here..."
                        className="form-control mb-2"
                    />
                    <button 
                        type="submit" 
                        className="btn btn-primary btn-black"
                    >
                        Submit
                    </button>
                    </form>
                </div>
            </div>

            {showGenericMacrosModal && (
              <GenericMacrosModal 
                show={showGenericMacrosModal}
                handleClose={() => setShowGenericMacrosModal(false)}
                mealType={selectedMealTypeForGeneric}
                onSubmit={handleAddGenericMacros}
              />
            )}
            {showManualEntryModal && (
              <ManualEntryModal 
                show={showManualEntryModal}
                handleClose={() => setShowManualEntryModal(false)}
                mealType={selectedMealTypeForManual}
                onSubmit={handleAddManualEntry}
              />
            )}
            {showEditFoodInfoModal && (
              <EditFoodInfoModal
                  show={showEditFoodInfoModal}
                  handleClose={() => {
                      setShowEditFoodInfoModal(false);
                      setSelectedFood(null);
                  }}
                  food={selectedFood}
                  onSave={handleSaveFoodInfo}
              />
            )}
            </div>
    );
}

export default Dashboard;